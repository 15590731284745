import { AppRoutes, TeamRoutes } from "../app/Routes";
import RoutesSettings from "../modules/Settings/Routes";
import React, { useContext } from "react";
import { CTACard, CTACardContent } from "../UIKit/CTACard";
import { AbilityScope, ApplicationContext, useAuth } from "../auth";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import Routes from "./Catalogues/Routes";

export function Dashboard() {
  const { clientId, supplierId } = useParams<{
    clientId: string;
    supplierId: string;
  }>();
  const auth = useAuth();
  const intl = useIntl();
  const application = useContext(ApplicationContext);

  const scope: AbilityScope | undefined = !!clientId
    ? { id: clientId }
    : undefined;

  const cardsContent: CTACardContent[] = [];
  cardsContent.push({
    title: intl.formatMessage({
      id: "catalogues.catalogues",
      defaultMessage: "Catalogs",
    }),
    icon: "CollectionIcon",
    text: intl.formatMessage({
      id: "catalogues.dashboard.catalogues.subtitle",
      defaultMessage:
        "Import all informations about your products, to make it available in your app.",
    }),
    destination: Routes(clientId, supplierId).catalogues,
    buttonText: intl.formatMessage({
      id: "dashboard.catalogues.cta",
      defaultMessage: "Manage your catalogs",
    }),
    neededAbilities: [
      {
        action: "list",
        subject: "catalogues",
        scope: { id: clientId },
      },
    ],
  });
  if (application?.enabledFeatures.includes("dealers")) {
    cardsContent.push({
      title: "dealers",
      icon: "ShoppingCartIcon",
      text: intl.formatMessage({
        id: "catalogues.dashboard.distributors.text",
        defaultMessage: "Manage dealers and their data",
      }),
      destination: Routes(clientId, supplierId).dealersImports,
      buttonText: intl.formatMessage({
        id: "catalogues.dashboard.distributors.cta",
        defaultMessage: "Manage dealers",
      }),
      neededAbilities: [
        { action: "list", subject: "dealers", scope: { id: clientId } },
      ],
    });
  }
  if (application?.enabledFeatures.includes("contentManagement")) {
    cardsContent.push({
      title: intl.formatMessage({
        id: "news.my.news",
        defaultMessage: "My news",
      }),
      text: intl.formatMessage({
        id: "news.introduction.body",
        defaultMessage:
          "News, videos, meetings, trade shows, news: keep your community informed!",
      }),
      destination: AppRoutes(clientId, supplierId).cms,
      buttonText: intl.formatMessage({
        id: "dashboard.news.cta",
        defaultMessage: "Keep your community informed\n",
      }),
      icon: "SpeakerphoneIcon",
      neededAbilities: [
        { action: "list", subject: "contentManagement", scope },
      ],
    });
  }
  if (application?.enabledFeatures.includes("community")) {
    cardsContent.push({
      title: intl.formatMessage({
        id: "community.title",
        defaultMessage: "My community",
      }),
      text: intl.formatMessage({
        id: "community.introduction.body",
        defaultMessage:
          "All data from the community of users of your application: registered pros, announcements from colleagues, contact messages",
      }),
      destination: AppRoutes(clientId, supplierId).community,
      buttonText: intl.formatMessage({
        id: "dashboard.community.cta",
        defaultMessage: "My community",
      }),
      icon: "UserGroupIcon",
      neededAbilities: [
        { action: "list", subject: "contentManagement", scope },
      ],
    });
  }
  cardsContent.push({
    title: intl.formatMessage({
      id: "metrics.metrics",
      defaultMessage: "statistics",
    }),
    text: intl.formatMessage({
      id: "metrics.introduction.body",
      defaultMessage: "All the data about how your application works",
    }),
    destination: AppRoutes(clientId, supplierId).metrics,
    buttonText: intl.formatMessage({
      id: "dashboard.metrics.cta",
      defaultMessage: "View statistics",
    }),
    icon: "TrendingUpIcon",
    neededAbilities: [{ action: "list", subject: "metrics", scope }],
  });
  cardsContent.push({
    title: intl.formatMessage({
      id: "settings.settings",
      defaultMessage: "Settings",
    }),
    text: intl.formatMessage({
      id: "settings.introduction.body",
      defaultMessage:
        "Privacy policy, contributors, and general settings of your application",
    }),
    destination: RoutesSettings(clientId, supplierId).privacyPolicy,
    buttonText: intl.formatMessage({
      id: "dashboard.settings.cta",
      defaultMessage: "Manage your settings",
    }),
    icon: "AdjustmentsIcon",
    neededAbilities: [{ action: "list", subject: "settings", scope }],
  });
  if (!clientId) {
    cardsContent.push({
      title: intl.formatMessage({
        id: "apps.applications",
        defaultMessage: "applications",
      }),
      text: intl.formatMessage({
        id: "apps.applications.body",
        defaultMessage: "Publication, settings, customization",
      }),
      destination: TeamRoutes().apps,
      buttonText: intl.formatMessage({
        id: "dashboard.apps.cta",
        defaultMessage: "Applications",
      }),
      icon: "PaperAirplaneIcon",
      neededAbilities: [{ action: "list", subject: "apps" }],
    });
  }

  return (
    <div>
      <div className="mt-6">
        <h2 className="font-black text-4xl">
          <FormattedMessage
            id="dashboard.all.features"
            defaultMessage="All DirBat features"
          />
        </h2>
        <hr className="mt-6 mb-6" />
        <div className="grid grid-cols-3 gap-4">
          {cardsContent.map((card) => (
            <CTACard content={card} key={card.destination} />
          ))}
        </div>
      </div>
      <h2 className="font-black text-4xl">Your app on the stores</h2>
      <hr className="mt-6 mb-6" />
      <div className="flex">
        <a href={application.playstoreUrl} className="flex" target="_blank">
          <img src="/googlePlay.png" className="w-44" />
        </a>
        <a href={application.appstoreUrl} className="flex" target="_blank">
          <img src="/appStore.png" className="w-40 m-3" />
        </a>
      </div>
    </div>
  );
}
